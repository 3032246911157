@charset "UTF-8";
@font-face {
  font-family: "DIN1451";
  src: url("../../fonts/din-1451-engschrift.otf") format("opentype");
}
@font-face {
  font-family: "arial-hebrew";
  src: url("../../fonts/arial-hebrew.ttf") format("truetype");
}
.pc-hidden {
  display: none;
}
@media screen and (max-width: 768px) {
  .pc-hidden {
    display: inline-block;
  }
}

.sp-hidden {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .sp-hidden {
    display: none;
  }
}

/*-- globalフォルダの中の_index.scss --*/
.l-main#top .fv {
  padding-top: 75px;
  padding-bottom: 65px;
  background-color: #F0F0F0;
}
@media screen and (max-width: 768px) {
  .l-main#top .fv {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.l-main#top .fv .fv__container {
  height: 521px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .l-main#top .fv .fv__container {
    height: 35rem;
  }
}
.l-main#top .fv .fv__title {
  font-size: 45px;
  font-weight: 500;
  line-height: 1.8222222222;
  letter-spacing: 0em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  position: absolute;
  top: 47%;
  left: calc(50% - 482px);
  transform: translateY(-50%);
  z-index: 5;
}
@media screen and (max-width: 1366px) {
  .l-main#top .fv .fv__title {
    left: 14.5%;
  }
}
@media screen and (max-width: 966px) {
  .l-main#top .fv .fv__title {
    left: 5%;
  }
}
@media screen and (max-width: 768px) {
  .l-main#top .fv .fv__title {
    font-size: 2.8rem;
  }
}
.l-main#top .fv .fv-swiper {
  width: min(100%, 50% + 264px);
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 1366px) {
  .l-main#top .fv .fv-swiper {
    width: 69.5%;
  }
}
.l-main#top .fv .fv-swiper .fv-img {
  height: 521px;
}
@media screen and (max-width: 768px) {
  .l-main#top .fv .fv-swiper .fv-img {
    height: 35rem;
  }
}
.l-main#top .fv .fv-swiper .fv-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.l-main#top .about {
  background-color: #F0F0F0;
}
.l-main#top .about .about__container {
  padding-top: 17px;
  padding-bottom: 88.5px;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about__container {
    padding-top: 1.7rem;
    padding-bottom: 8rem;
  }
}
.l-main#top .about .about__title {
  font-size: 35px;
  font-weight: 500;
  line-height: 2.3428571429;
  letter-spacing: 0.05em;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about__title {
    font-size: 2rem;
  }
}
.l-main#top .about .about-detail {
  margin-top: 4px;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about-detail {
    margin-top: 0.4rem;
  }
}
.l-main#top .about .about-detail .about-detail__text {
  font-size: 19px;
  font-weight: 500;
  line-height: 2.6315789474;
  letter-spacing: 0.07em;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about-detail .about-detail__text {
    font-size: 1.5rem;
  }
}
.l-main#top .about .about-btn {
  width: min(100%, 401px);
  margin-top: 82px;
  margin-inline: auto;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about-btn {
    width: min(100%, 25rem);
    margin-top: 4rem;
  }
}
.l-main#top .about .about-btn .about-btn__link {
  display: block;
  width: 100%;
}
.l-main#top .about .about-btn .about-btn__link .text {
  display: block;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.8181818182;
  letter-spacing: 0.3em;
  text-align: center;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
  padding: 1.4em 0.4em;
  color: #fff;
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about-btn .about-btn__link .text {
    font-size: 1.6rem;
    padding: 1em 0.4em;
  }
}
.l-main#top .about .about-btn .about-btn__link .border {
  display: block;
  padding-bottom: 92px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about-btn .about-btn__link .border {
    padding-bottom: 4.5rem;
  }
}
.l-main#top .about .about-btn .about-btn__link .border::before {
  content: "";
  display: inline-block;
  height: 100%;
  width: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
}
@media screen and (max-width: 768px) {
  .l-main#top .about .about-btn .about-btn__link .border::before {
    width: 0.1rem;
  }
}
.l-main#top .construction {
  margin-bottom: 240px;
  background-color: #fff;
}
@media screen and (max-width: 966px) {
  .l-main#top .construction {
    margin-bottom: 180px;
  }
}
@media screen and (max-width: 768px) {
  .l-main#top .construction {
    margin-bottom: 10rem;
  }
}
.l-main#top .construction .construction-list {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 966px) {
  .l-main#top .construction .construction-list {
    flex-wrap: wrap;
    row-gap: 0.1rem;
  }
}
.l-main#top .construction .construction-list .construction-list__item {
  width: calc(25% - 0.1rem);
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 966px) {
  .l-main#top .construction .construction-list .construction-list__item {
    height: 400px;
    width: calc(50% - 0.1rem);
  }
}
@media screen and (max-width: 768px) {
  .l-main#top .construction .construction-list .construction-list__item {
    height: 19rem;
  }
}
.l-main#top .construction .construction-list .construction-list__item:nth-child(1) {
  background-image: url(../../img/top/construction-bg01.jpg);
}
.l-main#top .construction .construction-list .construction-list__item:nth-child(1) .box {
  top: 50%;
}
.l-main#top .construction .construction-list .construction-list__item:nth-child(1) .big {
  letter-spacing: 0;
}
.l-main#top .construction .construction-list .construction-list__item:nth-child(2) {
  background-image: url(../../img/top/construction-bg02.jpg);
}
.l-main#top .construction .construction-list .construction-list__item:nth-child(3) {
  background-image: url(../../img/top/construction-bg03.jpg);
}
.l-main#top .construction .construction-list .construction-list__item:nth-child(4) {
  background-image: url(../../img/top/construction-bg04.jpg);
}
.l-main#top .construction .construction-list .link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.66);
  transition: background-color 0.2s ease;
}
@media (hover: hover) and (pointer: fine) {
  .l-main#top .construction .construction-list .link:hover {
    background-color: transparent;
  }
}
.l-main#top .construction .construction-list .link .box {
  width: min(100%, 201px);
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .l-main#top .construction .construction-list .link .box {
    top: 50%;
    width: 85%;
  }
}
.l-main#top .construction .construction-list .link .big {
  display: block;
  font-size: 25px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.2em;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .l-main#top .construction .construction-list .link .big {
    font-size: 1.7rem;
  }
}
.l-main#top .construction .construction-list .link .small {
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5384615385;
  letter-spacing: 0.1em;
  text-align: center;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .l-main#top .construction .construction-list .link .small {
    font-size: 1.2rem;
  }
}
.l-main#top .news {
  background-color: #F0F0F0;
  position: relative;
}
.l-main#top .news .news__titleEn {
  top: -10px;
  left: calc(50% - 623px);
}
@media screen and (max-width: 1200px) {
  .l-main#top .news .news__titleEn {
    top: -0.4rem;
    left: 4.375%;
  }
}
.l-main#top .news .news__titleJa {
  font-size: 27px;
  font-weight: 400;
  line-height: 1.8518518519;
  letter-spacing: 0.3em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#top .news .news__titleJa {
    font-size: 1.9rem;
  }
}
.l-main#top .news .news__container {
  padding-top: 159px;
  padding-bottom: 216px;
}
@media screen and (max-width: 768px) {
  .l-main#top .news .news__container {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.l-main#top .news .news-list {
  margin-top: 83px;
}
@media screen and (max-width: 768px) {
  .l-main#top .news .news-list {
    margin-top: 6rem;
  }
}
.l-main#top .news .news-list .news-list__item:last-child .link {
  border-bottom: 0.1rem solid #fff;
}
.l-main#top .news .news-list .link {
  display: flex;
  align-items: flex-start;
  padding: 23px 26px 28px;
  border-top: 0.1rem solid #fff;
}
@media screen and (max-width: 768px) {
  .l-main#top .news .news-list .link {
    display: block;
    padding: 1.5rem 1.5rem 2rem;
  }
}
.l-main#top .news .news-list .news-list__time {
  display: block;
  width: min(100%, 132px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin-top: 0.2em;
  color: #006EBC;
}
@media screen and (max-width: 768px) {
  .l-main#top .news .news-list .news-list__time {
    width: auto;
    font-size: 1.2rem;
  }
}
.l-main#top .news .news-list .news-list__title {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .l-main#top .news .news-list .news-list__title {
    font-size: 1.4rem;
    margin-top: 0.5em;
  }
}
.l-main#top .news .news__post {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 5rem;
}
@media screen and (max-width: 768px) {
  .l-main#top .news .news__post {
    font-size: 1.4rem;
  }
}
.l-main#top .sakurai {
  position: relative;
  overflow: hidden;
}
.l-main#top .sakurai .sakurai__titleEn {
  top: -10px;
  left: calc(50% - 623px);
}
@media screen and (max-width: 1200px) {
  .l-main#top .sakurai .sakurai__titleEn {
    top: -0.4rem;
    left: 4.375%;
  }
}
.l-main#top .sakurai .sakurai__container {
  padding-top: 250px;
  padding-bottom: 202px;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .sakurai__container {
    padding-top: 15rem;
    padding-bottom: 14.5rem;
  }
}
.l-main#top .sakurai .banner .banner__item {
  height: 130px;
  margin-top: 50px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .banner .banner__item {
    height: 10rem;
    margin-top: 3rem;
  }
}
.l-main#top .sakurai .banner .banner__item:first-child {
  margin-top: 0;
}
.l-main#top .sakurai .banner .banner__item:nth-child(1) .banner__inner::before {
  background-image: url(../../img/top/sakurai-link01.png);
}
.l-main#top .sakurai .banner .banner__item:nth-child(2) .banner__inner::before {
  background-image: url(../../img/top/sakurai-link02.png);
}
.l-main#top .sakurai .banner .banner__item:nth-child(3) .banner__inner::before {
  background-image: url(../../img/top/sakurai-link03.png);
}
.l-main#top .sakurai .banner .banner__link {
  display: block;
  height: 100%;
  background: linear-gradient(to right, #0081F0 0%, #6ED2FF 60%);
  position: relative;
}
.l-main#top .sakurai .banner .banner__link .banner__inner {
  display: block;
  height: 100%;
  position: relative;
}
.l-main#top .sakurai .banner .banner__link .banner__inner::before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  position: absolute;
  width: 56.3%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 5;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .banner .banner__link .banner__inner::before {
    width: 79%;
    right: -7rem;
  }
}
.l-main#top .sakurai .banner .banner__link .box {
  width: min(100%, 300px);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .banner .banner__link .box {
    width: min(100%, 17rem);
  }
}
.l-main#top .sakurai .banner .banner__link .box .big {
  display: block;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3636363636;
  letter-spacing: 0.3em;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .banner .banner__link .box .big {
    font-size: 1.6rem;
  }
}
.l-main#top .sakurai .banner .banner__link .box .small {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: 0.3em;
  text-align: center;
  padding: 0em 0.9em;
  margin-top: 0.3em;
  border: 0.1rem solid #fff;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .banner .banner__link .box .small {
    font-size: 1.3rem;
  }
}
.l-main#top .sakurai .otherLink {
  display: flex;
  justify-content: space-between;
  margin-top: 72px;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .otherLink {
    margin-top: 5rem;
  }
}
.l-main#top .sakurai .otherLink .otherLink__item {
  width: 48.3%;
  height: 195px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .otherLink .otherLink__item {
    height: 15rem;
  }
}
.l-main#top .sakurai .otherLink .otherLink__item:nth-child(1) {
  background-image: url(../../img/top/sakurai-otherlink01.jpg);
}
.l-main#top .sakurai .otherLink .otherLink__item:nth-child(2) {
  background-image: url(../../img/top/sakurai-otherlink02.jpg);
}
.l-main#top .sakurai .otherLink .otherLink__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3636363636;
  letter-spacing: 0.3em;
  text-align: center;
  height: 100%;
  color: #fff;
  background: linear-gradient(to right, rgba(0, 129, 240, 0.46) 0%, rgba(110, 210, 255, 0.46) 100%);
}
@media screen and (max-width: 768px) {
  .l-main#top .sakurai .otherLink .otherLink__link {
    font-size: 1.6rem;
  }
}