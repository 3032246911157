@charset "UTF-8";
@use "../global" as *;

.l-main#top {

    .fv {
        padding-top: 75px;
        padding-bottom: 65px;
        background-color: $color-gray3;
        @include bpTb {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
        .fv__container {
            height: 521px;
            position: relative; 
            @include bpTb {
                height: 35rem;
            }
        }
        .fv__title {
            font-size: $text-45;
            @include font(45, 500, 82, 0);
            font-family: $mincho;
            position: absolute;
            top: 47%;
            left: calc(50% - 482px);
            transform: translateY(-50%);
            z-index: 5;
            @include breakpoint(1366px) {
                left: 14.5%;
            }
            @include bpPcs {
                left: 5%;
            }
            @include bpTb {
                font-size: $text-45-sp;
            }
        }
        .fv-swiper {
            width: min(100%, calc(50% + 264px));
            margin-left: auto;
            margin-right: 0;
            @include breakpoint(1366px) {
                width: min(100%, 69.5%);
            }
            .fv-img {
                height: 521px;
                @include bpTb {
                    height: 35rem;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .about {
        background-color: $color-gray3;
        .about__container {
            padding-top: 17px;
            padding-bottom: 88.5px;
            @include bpTb {
                padding-top: 1.7rem;
                padding-bottom: 8rem;
            }
        }
        .about__title {
            font-size: $text-35;
            @include font(35, 500, 82, 50);
            font-family: $mincho;
            text-align: center;
            @include bpTb {
                font-size: $text-35-sp;
            }
        }
        .about-detail {
            margin-top: 4px;
            @include bpTb {
                margin-top: 0.4rem;
            }
            .about-detail__text {
                font-size: $text-19;
                @include font(19, 500, 50, 70);
                text-align: center;
                font-family: $mincho;
                @include bpTb {
                    font-size: $text-19-sp;
                }
            }
        }
        .about-btn {  
            width: min(100%, 401px);
            margin-top: 82px;
            margin-inline: auto; 
            @include bpTb {
                width: min(100%, 25rem);
                margin-top: 4rem;
            }
            .about-btn__link {
                display: block;
                width: 100%;
                .text {
                    display: block;
                    font-size: $text-22;
                    @include font(22, 500, 40, 300);
                    text-align: center;
                    font-family: $mincho;
                    padding: 1.4em 0.4em;
                    color: $whiteColor;
                    background-color: $color-black1;
                    @include bpTb {
                        font-size: $text-22-sp;
                        padding: 1em 0.4em;
                    }
                }
                .border {
                    display: block;
                    padding-bottom: 92px;
                    position: relative;
                    @include bpTb {
                        padding-bottom: 4.5rem;
                    }
                    &::before {
                        content: '';
                        display: inline-block;
                        height: 100%;
                        width: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $color-black1;
                        @include bpTb {
                            width: 0.1rem;
                        }
                    }
                }
            }
        }
    }

    .construction {
        margin-bottom: 240px;
        background-color: $whiteColor;
        @include bpPcs {
            margin-bottom: 180px;
        }
        @include bpTb {
            margin-bottom: 10rem;
        }
        .construction__container {

        }
        .construction-list {
            display: flex;
            justify-content: space-between;
            @include bpPcs {
                flex-wrap: wrap;
                row-gap: 0.1rem;
            }
            .construction-list__item {
                width: calc((100% / 4) - 0.1rem);
                height: 450px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                @include bpPcs {
                    height: 400px;
                    width: calc((100% / 2) - 0.1rem);
                }
                @include bpTb {
                    height: 19rem;
                }
                &:nth-child(1) {
                    background-image: url(../../img/top/construction-bg01.jpg);
                    .box {
                        top: 50%;
                    }
                    .big {
                        letter-spacing: 0;
                    }
                }
                &:nth-child(2) {
                    background-image: url(../../img/top/construction-bg02.jpg);
                }
                &:nth-child(3) {
                    background-image: url(../../img/top/construction-bg03.jpg);
                }
                &:nth-child(4) {
                    background-image: url(../../img/top/construction-bg04.jpg);
                }
            }
            .link {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                background-color:rgba(0,0,0,0.66);
                transition: background-color .2s ease;
                @include hover {
                    background-color: transparent;
                }
                .box {
                    width: min(100%, 201px);
                    position: absolute;
                    top: 47%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @include bpTb {
                        top: 50%;
                        width: 85%;
                    }
                }
                .big {
                    display: block;
                    font-size: $text-25;
                    @include font(25, 500, 50, 200);
                    text-align: center;
                    color: $whiteColor;
                    @include bpTb {
                        font-size: $text-25-sp;
                    }
                }
                .small {
                    display: block;
                    font-size: $text-13;
                    @include font(13, 400, 20, 100);
                    text-align: center;
                    background-color: $whiteColor;
                    @include bpTb {
                        font-size: $text-13-sp;
                    }
                }
            }
        }
    }

    .news  {
        background-color: $color-gray3;
        position: relative;
        .news__titleEn {
            top: -10px;
            left: calc(50% - 623px);
            @include bpPc {
                top: -0.4rem;
                left: $baseWidthPadding;
            }
        }
        .news__titleJa {
            font-size: $text-27;
            @include font(27, 400, 50, 300);
            text-align: center;
            @include bpTb {
                font-size: $text-27-sp;
            }
        }
        .news__container {
            padding-top: 159px;
            padding-bottom: 216px;
            @include bpTb {
                padding-top: 10rem;
                padding-bottom: 10rem;
            }
        }
        .news-list {
            margin-top: 83px;
            @include bpTb {
                margin-top: 6rem;
            }
            .news-list__item {
                &:last-child {
                    .link {
                        border-bottom: 0.1rem solid $whiteColor;
                    }
                }
            }
            .link {
                display: flex;
                align-items: flex-start;
                padding: 23px 26px 28px;
                border-top: 0.1rem solid $whiteColor;
                @include bpTb {
                    display: block;
                    padding: 1.5rem 1.5rem 2rem;
                }
            }
            .news-list__time {
                display: block;
                width: min(100%, 132px);
                font-size: $text-14;
                @include font(14, 400, 21, 50);
                margin-top: 0.2em;
                color: $color-blue4;
                @include bpTb {
                    width: auto;
                    font-size: $text-14-sp;
                }
            }
            .news-list__title {
                flex: 1;
                font-size: $text-16;
                @include font(16, 400, 24, 50);
                @include bpTb {
                    font-size: $text-16-sp;
                    margin-top: 0.5em;
                }
            }
        }
        .news__post {
            font-size: $text-16;
            @include font(16, 400, 24, 50);
            text-align: center;
            margin-top: 5rem;
            @include bpTb {
                font-size: $text-16-sp;
            }
        }
    }

    .sakurai {
        position: relative;
        overflow: hidden;
        .sakurai__titleEn {
            top: -10px;
            left: calc(50% - 623px);
            @include bpPc {
                top: -0.4rem;
                left: $baseWidthPadding;
            }
        }
        .sakurai__container {
            padding-top: 250px;
            padding-bottom: 202px;
            @include bpTb {
                padding-top: 15rem;
                padding-bottom: 14.5rem;
            }
        }
        .banner {
            .banner__item {
                height: 130px;
                margin-top: 50px;
                overflow: hidden;
                @include bpTb {
                    height: 10rem;
                    margin-top: 3rem;
                }
                &:first-child {
                    margin-top: 0;
                }
                &:nth-child(1) {
                    .banner__inner {
                        &::before {
                            background-image: url(../../img/top/sakurai-link01.png);
                        }
                    }
                }
                &:nth-child(2) {
                    .banner__inner {
                        &::before {
                            background-image: url(../../img/top/sakurai-link02.png);
                        }
                    }
                }
                &:nth-child(3) {
                    .banner__inner {
                        &::before {
                            background-image: url(../../img/top/sakurai-link03.png);
                        }
                    }
                }
            }
            .banner__link {
                display: block;
                height: 100%;
                background: linear-gradient(to right, #0081F0 0%, #6ED2FF 60%);
                position: relative;
                .banner__inner {
                    display: block;
                    height: 100%;
                    position: relative;
                    &::before {
                        content: '';
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: top right;
                        position: absolute;
                        width: 56.3%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        z-index: 5;
                        @include bpTb {
                            width: 79%;
                            right: -7rem;
                        }
                    }
                }
                .box {
                    width: min(100%, 300px);
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    color: $whiteColor;
                    @include bpTb {
                        width: min(100%, 17rem);
                    }
                    .big {
                        display: block;
                        font-size: $text-22;
                        @include font(22, 400, 30, 300);
                        text-align: center;
                        @include bpTb {
                            font-size: $text-22-sp;
                        }
                    }
                    .small {
                        display: inline-block;
                        font-size: $text-15;
                        @include font(15, 400, 20, 300);
                        text-align: center;
                        padding: 0em 0.9em;
                        margin-top: 0.3em;
                        border: 0.1rem solid $whiteColor;
                        @include bpTb {
                            font-size: $text-15-sp;
                        }
                    }
                }
            }
        }
        .otherLink {
            display: flex;
            justify-content: space-between;
            margin-top: 72px;
            @include bpTb {
                margin-top: 5rem;
            }
            .otherLink__item {
                width: min(100%, 48.3%);
                height: 195px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                @include bpTb {
                    height: 15rem;
                }
                &:nth-child(1) {
                    background-image: url(../../img/top/sakurai-otherlink01.jpg);
                }
                &:nth-child(2) {
                    background-image: url(../../img/top/sakurai-otherlink02.jpg);
                }
            }
            .otherLink__link {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $text-22;
                @include font(22, 400, 30, 300);
                text-align: center;
                height: 100%;
                color: $whiteColor;
                background: linear-gradient(to right, rgba(0,129,240,0.46) 0%, rgba(110,210,255,0.46) 100%);
                @include bpTb {
                    font-size: $text-22-sp;
                }
            }
        }
    }
}